import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import( '../views/Home.vue')
  },
  {
    path: '/examtask',
    redirect: {/* webpackChunkName: "home" */  name: 'Home' }
  }, 
  {
    path: '/examtask/:examStudentTaskId',
    name: 'ExamTask',
    component: () => import(/* webpackChunkName: "task" */  '../views/Exam/Task.vue')
  },  
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Exam/Test.vue')
  },    
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "external" */ '../views/Auth/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "external " */ '../views/Auth/Logout.vue')
  },
  {
    path: '/browser',
    name: 'Browser',
    component: () => import(/* webpackChunkName: "external " */ '../views/Browser.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'Logout'  && to.name !== 'Browser' && document.cookie.indexOf('qAssess-Preview-Login=') === -1) {
    next({ name: 'Login' });
  }
  else {
    next();
  }
})

export default router;
