import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/custom.scss'
import VueMask from 'v-mask'
import VuejsDialog from 'vuejs-dialog';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMask);
Vue.use(VuejsDialog);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
